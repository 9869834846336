import React from "react"
import PropTypes from "prop-types"
import { categoryStyles } from "./CategoryItem-tw-styles"

const CategoryItem = ({ index, name, bgColor }) => {
    return (
        <div
            className={categoryStyles.wrapper}
            style={{ order: index + 1, backgroundColor: bgColor }}
        >
            {name}
        </div>
    )
}


CategoryItem.propTypes = {
    index: PropTypes.number,
    name: PropTypes.string,
    bgColor: PropTypes.string,
}


export default CategoryItem
